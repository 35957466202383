export const PageNotFoundIcon: React.FC = () => (
  <svg
    focusable="false"
    height="54"
    viewBox="0 0 62 54"
    width="62"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <g
        stroke="#4d4d4d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="m8 7h4" />
        <path d="m18 7h4" />
        <path d="m41 7h11" />
      </g>
      <path
        d="m29.2522796 40v-1.0350195c0-.7146562.0364742-1.3369001.1094225-1.8667315.0729484-.5298314.206687-1.0165369.4012158-1.4601167.1945289-.4435798.4620061-.8625162.8024316-1.2568093.3404256-.3942932.7781155-.8132296 1.31307-1.2568094.6322188-.542153 1.2036474-1.0596628 1.7142857-1.5525292s.9422492-.9980544 1.2948328-1.5155642c.3525836-.5175097.6261398-1.0719844.8206687-1.6634241s.2917933-1.2568093.2917933-1.9961089c0-1.7743191-.5775076-3.1173801-1.7325228-4.0291829-1.1550152-.9118029-2.7173252-1.3677043-4.6869301-1.3677043-1.1428571 0-2.1762918.1170558-3.1003039.3511673-.9240122.2341116-1.7507599.5236706-2.4802432.8686771l.4012158 1.5894941c.7051672-.3450065 1.4893617-.616083 2.3525836-.8132296.8632219-.1971465 1.7446808-.2957198 2.6443769-.2957198 1.3860182 0 2.4924012.3142023 3.3191489.942607s1.2401216 1.5710117 1.2401216 2.827821c0 .5914397-.0790273 1.1151103-.2370821 1.5710117-.1580547.4559014-.3890577.8933204-.6930091 1.3122568-.3039513.4189364-.668693.8317121-1.0942249 1.2383269-.4255319.4066147-.9057751.831712-1.4407295 1.2752918-.5835866.4928664-1.0699088.9610895-1.4589665 1.4046692-.3890578.4435798-.7051672.9179637-.9483283 1.4231518s-.4194529 1.0843061-.5288754 1.7373541-.1641337 1.4354734-.1641337 2.3472762v1.2198444zm-.7361506 6c.4731183 0 .8387097-.1413043 1.0967742-.423913s.3870968-.6413044.3870968-1.076087-.1290323-.7934783-.3870968-1.076087-.6236559-.423913-1.0967742-.423913c-.4946236 0-.8709677.1413043-1.1290322.423913s-.3870968.6413044-.3870968 1.076087.1290323.7934783.3870968 1.076087.6344086.423913 1.1290322.423913z"
        fill="#ff5900"
        fillRule="nonzero"
      />
      <path
        d="m0 14h60"
        stroke="#4d4d4d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="m0 0h60v52h-60z"
        stroke="#4d4d4d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
