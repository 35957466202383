// libraries
import { useTranslate } from "@dcl/tools";

// components
import { Section } from "../../../../components";

// utilities
import { getClassNameFactory } from "../../../../utilities";

// constants
import { DISPLAY_NAME, benefits } from "./BenefitsConstants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Benefits: React.FC = () => {
  const translate = useTranslate();

  return (
    <div className={getClassName()}>
      <Section>
        <ul className={getClassName("list")}>
          {benefits.map(({ description, heading, icon }) => (
            <li className={getClassName("item")} key={heading}>
              <span className={getClassName("icon")}>{icon}</span>
              <h3 className={getClassName("heading")}>{translate(heading)}</h3>
              <p className={getClassName("description")}>
                {translate(description)}
              </p>
            </li>
          ))}
        </ul>
      </Section>
    </div>
  );
};

Benefits.displayName = DISPLAY_NAME;
