export const Check_1_Icon: React.FC = () => (
  <svg
    focusable="false"
    height="65"
    viewBox="0 0 65 65"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeLinecap="round" strokeWidth="2">
      <path
        d="M41.999 41.374C38.277 40.577 33.636 40 28 40c-11.14 0-18.494 2.331-22.453 4.087A5.977 5.977 0 0 0 2 49.567V60h28M14 16c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 16-14 16-14-8.268-14-16z"
        stroke="#404040"
      />
      <path d="M40 54l6 6 14-14" stroke="#FF6400" />
    </g>
  </svg>
);
