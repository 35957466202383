// library
import { SafAnchor, SafButton, SafIcon } from "@saffron/core-components/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { EnterpriseDialog } from "../../../EnterpriseDialog";
// utilities
import { getClassNameFactory, getFullPath } from "../../../../utilities";

// constants
import { DISPLAY_NAME } from "./NavigationConstants";
import { DialogsIds, paths } from "../../../../common/constants";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const Navigation: React.FC = () => {
  const translate = useTranslate();
  const { isAuthenticated, logout } = useAuth0();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => setIsDialogOpen(false);

  const handleOpen = () => setIsDialogOpen(true);

  const handleSignOut = () => {
    handleClose();
    logout({
      returnTo: getFullPath(paths.signout),
    });
  };

  return (
    <div className={getClassName()}>
      <ul className={getClassName("list")}>
        <li>
          <SafAnchor
            aria-label={`${translate(
              "AccessibilityHint.HelpSupportLinkText"
            )} ${translate("AccessibilityHint.NewTab")}`}
            className={getClassName("supportLink")}
            href={translate("Link.HelpSupport")}
            rel="noreferrer"
            target="_blank"
            title={translate("AccessibilityHint.HelpSupportLinkText")}
          >
            <SafIcon icon-name="question-circle" />
          </SafAnchor>
        </li>
        {isAuthenticated && (
          <li>
            <SafButton appearance="tertiary" onClick={handleOpen}>
              {translate("Button.SignOut")}
            </SafButton>
          </li>
        )}
      </ul>
      <EnterpriseDialog
        dialogTitle={translate("SignOutDialog.Heading")}
        id={DialogsIds.SIGN_OUT}
        isOpen={isDialogOpen}
        onClose={handleClose}
        onPrimaryButtonClick={handleSignOut}
        onSecondaryButtonClick={handleClose}
        primaryButtonText={translate("Button.SignOut")}
        secondaryButtonText={translate("Button.Cancel")}
      >
        {translate("SignOutDialog.Description")}
      </EnterpriseDialog>
    </div>
  );
};
