export const AlertTriangleIcon: React.FC = () => (
  <svg
    fill="currentColor"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5c-.535 0-1.091.313-1.356.767L.196 20.211a1.555 1.555 0 000 1.511c.311.533.623.778 1.22.778h21.168c.597.002.908-.244 1.22-.777a1.552 1.552 0 000-1.512L13.357 2.268C13.09 1.814 12.534 1.5 12 1.5zm-.122 1.885l10.222 17.5H1.655l10.222-17.5z"
      fill="currentColor"
    />
    <path
      d="M11.143 8.22h1.714v7.56h-1.714V8.22zM12 19.14a.872.872 0 01-.606-.246.84.84 0 01-.251-.594.825.825 0 01.251-.594.858.858 0 01.606-.246c.227 0 .445.088.606.246a.831.831 0 010 1.188.866.866 0 01-.606.246z"
      fill="currentColor"
    />
  </svg>
);
