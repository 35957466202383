// libraries
import classNames from "classnames";

// components
import {
  AlertCircle_1_Icon as AlertIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
} from "../../common/icons";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { AlertBannerType, DISPLAY_NAME } from "./AlertBannerConstants";

// types
import { IAlertBannerProps } from "./AlertBannerTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

const renderAlertStateIcon = (bannerType: AlertBannerType): JSX.Element => {
  switch (bannerType) {
    case AlertBannerType.ATTENTION:
      return <AlertTriangleIcon />;
    case AlertBannerType.CONFIRMATION:
      return <CheckCircleIcon />;
    case AlertBannerType.INFORMATION:
    case AlertBannerType.WARNING:
    case AlertBannerType.ERROR:
      return <AlertIcon />;
  }
};

export const AlertBanner: React.FC<IAlertBannerProps> = ({
  bannerType,
  children,
  className,
}) => {
  const isColoredWarningText = bannerType === AlertBannerType.WARNING;

  return (
    <div
      className={classNames(
        getClassName({ modifiers: classNames(bannerType) }),
        className
      )}
    >
      <div className={getClassName({ descendantName: "wrapper" })}>
        <div className={getClassName({ descendantName: "icon" })}>
          {renderAlertStateIcon(bannerType)}
        </div>
        <div className={getClassName({ descendantName: "content" })}>
          <div className={getClassName({ descendantName: "textContainer" })}>
            <span
              className={getClassName({
                descendantName: "text",
                modifiers: classNames(isColoredWarningText && "colored"),
              })}
            >
              {children}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

AlertBanner.displayName = DISPLAY_NAME;
