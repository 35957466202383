// libraries
import { useTranslate } from "@dcl/tools";

// components
import { Dropdown } from "../Dropdown";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { AccessibilityClassnames } from "../../common/constants";
import {
  DISPLAY_NAME,
  LABEL_ID,
  MAX_DROPDOWN_VISIBLE_ITEMS,
} from "./PreferredLanguageDropdownConstants";

// types
import {
  DropdownOnChangeHandler,
  PreferredLanguageDropdownProps,
} from "./PreferredLanguageDropdownTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const PreferredLanguageDropdown: React.FC<
  PreferredLanguageDropdownProps
> = ({ label, items, value, onChange }) => {
  const translate = useTranslate();

  return (
    <div className={getClassName()}>
      <label className={getClassName("label")} id={LABEL_ID}>
        <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
          {translate("AccessibilityHint.PreferredLanguage")}
        </span>
        <span aria-hidden>{label}</span>
      </label>
      <Dropdown
        items={items}
        labeledBy={LABEL_ID}
        maxVisibleItems={MAX_DROPDOWN_VISIBLE_ITEMS}
        onValueChanged={onChange as DropdownOnChangeHandler}
        placeholder={translate("Field.DropdownPlaceholder")}
        value={value}
      />
    </div>
  );
};

PreferredLanguageDropdown.displayName = DISPLAY_NAME;
