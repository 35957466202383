// libraries
import { isValidPhoneNumber } from "react-phone-number-input";

// utilities
import {
  areEqual,
  isEmpty,
  isMaximumLengthExceeded,
  isMinimumLengthReached,
  isPatternMismatched,
} from "./validationHooksUtilities";
import { getPasswordScore } from "../../components/PasswordRequirements";

// constants
import {
  REQUIRED_PASSWORD_SCORE,
  answerRequirements,
  firstNameRequirements,
  lastNameRequirements,
  otpAppNameRequirements,
  passwordRequirements,
  phoneNumberErrors,
  regKeyNameRequirements,
  regKeyRequirements,
} from "../../common/constants";

// types
import { Passwords, ValidationRule } from ".";

export const answerValidationRules: ValidationRule[] = [
  (answer) => isEmpty(answer) && answerRequirements.errors.valueMissing,

  (answer) =>
    !isMinimumLengthReached(answer, answerRequirements.minimumLength) &&
    answerRequirements.errors.minimumLengthIsNotReached,

  (answer) =>
    isPatternMismatched(answer, answerRequirements.pattern) &&
    answerRequirements.errors.patternMismatch,

  (answer) =>
    isMaximumLengthExceeded(answer, answerRequirements.maximumLength) &&
    answerRequirements.errors.maximumLengthExceeded,
];

export const currentPasswordValidationRules: ValidationRule<Passwords>[] = [
  ({ currentPassword }) =>
    isEmpty(currentPassword) && passwordRequirements.errors.passwordMissing,

  ({ currentPassword }) =>
    isPatternMismatched(currentPassword, passwordRequirements.pattern) &&
    passwordRequirements.errors.patternMismatch,

  ({ currentPassword }) =>
    !isMinimumLengthReached(
      currentPassword,
      passwordRequirements.minimumLength
    ) && passwordRequirements.errors.minimumLengthIsNotReached,
];

export const firstNameValidationRules: ValidationRule[] = [
  (firstName) =>
    isEmpty(firstName) && firstNameRequirements.errors.valueMissing,

  (firstName) =>
    isMaximumLengthExceeded(firstName, firstNameRequirements.maximumLength) &&
    firstNameRequirements.errors.maximumLengthExceeded,
];

export const lastNameValidationRules: ValidationRule[] = [
  (lastName) => isEmpty(lastName) && lastNameRequirements.errors.valueMissing,

  (lastName) =>
    isMaximumLengthExceeded(lastName, lastNameRequirements.maximumLength) &&
    lastNameRequirements.errors.maximumLengthExceeded,
];

export const newPasswordValidationRules: ValidationRule<Passwords>[] = [
  ({ newPassword }) =>
    isEmpty(newPassword) && passwordRequirements.errors.passwordMissing,

  ({ newPassword }) =>
    isPatternMismatched(newPassword, passwordRequirements.pattern) &&
    passwordRequirements.errors.patternMismatch,

  ({ currentPassword, newPassword }) =>
    areEqual(newPassword, currentPassword) &&
    passwordRequirements.errors.matchingCurrentPassword,

  ({ newPassword }) =>
    !isMinimumLengthReached(newPassword, passwordRequirements.minimumLength) &&
    passwordRequirements.errors.minimumLengthIsNotReached,

  ({ newPassword }) =>
    getPasswordScore(newPassword) < REQUIRED_PASSWORD_SCORE &&
    passwordRequirements.errors.weakPassword,
];

export const otpAppValidationRules: ValidationRule[] = [
  (otpAppName) =>
    isEmpty(otpAppName) && otpAppNameRequirements.errors.valueMissing,

  (otpAppName) =>
    isMaximumLengthExceeded(otpAppName, otpAppNameRequirements.maximumLength) &&
    otpAppNameRequirements.errors.maximumLengthExceeded,
];

export const passwordConfirmationValidationRules: ValidationRule<Passwords>[] =
  [
    ({ passwordConfirmation }) =>
      isEmpty(passwordConfirmation) &&
      passwordRequirements.errors.passwordConfirmationMissing,

    ({ passwordConfirmation }) =>
      isPatternMismatched(passwordConfirmation, passwordRequirements.pattern) &&
      passwordRequirements.errors.patternMismatch,

    ({ newPassword, passwordConfirmation }) =>
      !areEqual(newPassword, passwordConfirmation) &&
      passwordRequirements.errors.passwordsMismatch,

    ({ currentPassword, passwordConfirmation }) =>
      areEqual(passwordConfirmation, currentPassword) &&
      passwordRequirements.errors.matchingCurrentPassword,

    ({ passwordConfirmation }) =>
      !isMinimumLengthReached(
        passwordConfirmation,
        passwordRequirements.minimumLength
      ) && passwordRequirements.errors.minimumLengthIsNotReached,
  ];

export const phoneNumberValidationRules: ValidationRule[] = [
  (phoneNumber) => isEmpty(phoneNumber) && phoneNumberErrors.phoneNumberMissing,
  (phoneNumber) =>
    !isValidPhoneNumber(phoneNumber) && phoneNumberErrors.invalidPhoneNumber,
];

export const regKeyValidationRules: ValidationRule[] = [
  (regKey) => isEmpty(regKey) && regKeyRequirements.errors.valueMissing,
  (regKey) =>
    isMaximumLengthExceeded(regKey, regKeyRequirements.maximumLength) &&
    regKeyRequirements.errors.maximumLengthExceeded,
];

export const regKeyNameValidationRules: ValidationRule[] = [
  (regKeyName) =>
    isEmpty(regKeyName) && regKeyNameRequirements.errors.valueMissing,
  (regKey) =>
    isMaximumLengthExceeded(regKey, regKeyRequirements.maximumLength) &&
    regKeyRequirements.errors.maximumLengthExceeded,
];
