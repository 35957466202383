// libraries
import classNames from "classnames";
import { useTranslate } from "@dcl/tools";

// components
import { CheckCircle_2_Icon as CheckCircleIcon } from "../../common/icons";
import { Heading } from "../Heading"; // don't change to index, it crashes unit tests

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import {
  AccessibilityClassnames,
  AriaLiveTypes,
  passwordRequirements,
} from "../../common/constants";
import {
  DISPLAY_NAME,
  PASSWORD_REQUIREMENTS_ID,
  Requirements,
} from "./PasswordRequirementsConstants";
import { HeadingRank, HeadingTypes } from "../Heading";

// types
import { PasswordRequirementsProps } from "./PasswordRequirementsTypes";

const getClassName = getClassNameFactory(DISPLAY_NAME);

export const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  className,
  requirements,
}) => {
  const translate = useTranslate();

  const formatRequirements = requirements.filter(
    ({ textKey }) => textKey !== Requirements.LENGTH
  );

  const lengthRequirement = requirements.filter(
    ({ textKey }) => textKey === Requirements.LENGTH
  )[0];

  return (
    <div
      className={classNames(getClassName(), className)}
      id={PASSWORD_REQUIREMENTS_ID}
    >
      <Heading
        className={getClassName("heading")}
        rank={HeadingRank.Third}
        type={HeadingTypes.Small}
      >
        {translate("PasswordRequirements.Heading")}
      </Heading>
      <p
        aria-live={AriaLiveTypes.POLITE}
        className={getClassName({
          descendantName: "item",
          modifiers: lengthRequirement.isMet && "met",
        })}
        data-testid={lengthRequirement.textKey}
      >
        <span className={getClassName("icon")}>
          <CheckCircleIcon />
        </span>
        <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
          {lengthRequirement.isMet
            ? `${translate(lengthRequirement.textKey, [
                passwordRequirements.minimumLength,
              ])} ${translate("AccessibilityHint.Met")}`
            : `${translate(lengthRequirement.textKey, [
                passwordRequirements.minimumLength,
              ])} ${translate("AccessibilityHint.Unmet")}`}
        </span>
        <span className={getClassName("text")} aria-hidden>
          {translate(lengthRequirement.textKey, [
            passwordRequirements.minimumLength,
          ])}
        </span>
      </p>
      <Heading
        className={getClassName("heading")}
        rank={HeadingRank.Third}
        type={HeadingTypes.Small}
      >
        {translate("PasswordRequirements.DescriptionMinimumRulesNumber")}
      </Heading>
      <ul aria-live={AriaLiveTypes.POLITE}>
        {formatRequirements.map(({ isMet, textKey }) => (
          <li
            className={getClassName({
              descendantName: "item",
              modifiers: isMet && "met",
            })}
            data-testid={textKey}
            key={textKey}
          >
            <span className={getClassName("icon")}>
              <CheckCircleIcon />
            </span>
            <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
              {isMet
                ? `${translate(textKey)} ${translate("AccessibilityHint.Met")}`
                : `${translate(textKey)} ${translate(
                    "AccessibilityHint.Unmet"
                  )}`}
            </span>
            <span className={getClassName("text")} aria-hidden>
              {translate(textKey)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

PasswordRequirements.displayName = DISPLAY_NAME;
