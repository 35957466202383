// libraries
import React from "react";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";

// components
import { DropdownHeader, DropdownItem } from "./components";

// utilities
import { getClassNameFactory } from "../../utilities";

// constants
import { BASE_FONT_SIZE, DISPLAY_NAME } from "./DropdownConstants";
import {
  DirectionTypes,
  KeyboardKeys,
  RoleTypes,
} from "../../common/constants";

// types
import {
  IDropdownItem,
  IDropdownItemProps,
  IDropdownProps,
  IDropdownState,
} from "./DropdownTypes";

export class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  listRef = React.createRef<HTMLUListElement>();
  toggleButtonRef = React.createRef<HTMLButtonElement>();
  selectedLinkRef = React.createRef<HTMLAnchorElement>();
  filterContainerRef = React.createRef<HTMLInputElement>();

  public state = {
    index: 0,
    isExpanded: false,
    maxListHeight: "0", // to prevent blinking in firefox
    value: this.props.value,
  };

  public componentDidUpdate = () => {
    if (!this.state.isExpanded || !this.selectedLinkRef.current) {
      return;
    }

    this.selectedLinkRef.current?.focus();
  };

  private getClassName = getClassNameFactory(DISPLAY_NAME);

  private expandButtonId: string = uniqueId("expand-button-");
  private filterInputId: string = uniqueId("filter-input-id-");

  setFocusOnList = () => {
    if (this.listRef && this.listRef.current) {
      this.listRef.current.focus();
    }
  };

  private collapse = (): void => {
    this.setState({ isExpanded: false });

    if (this.props.onDropdownBlur) {
      this.props.onDropdownBlur();
    }

    if (this.toggleButtonRef && this.toggleButtonRef.current) {
      this.toggleButtonRef.current.focus();
    }
  };

  private expand = () => {
    const { items = [] } = this.props;
    const { value } = this.state;

    const index = items.findIndex((item) => item.value === value);

    this.setState({ index, isExpanded: true }, () => {
      this.setFocusOnList();
    });
  };

  private toggleExpand = (): void => {
    if (this.state.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  };

  private handleBlur = (event): void => {
    if (!this.state.isExpanded) {
      return;
    }

    const relatedTarget: EventTarget =
      event.relatedTarget || (document.activeElement as EventTarget);

    if (
      relatedTarget === this.listRef.current ||
      (relatedTarget as Element).id === this.expandButtonId
    ) {
      return;
    }

    const filterContainer = this.filterContainerRef.current;

    if (filterContainer && filterContainer.contains(relatedTarget as Element)) {
      return;
    }

    if (
      typeof event === "undefined" ||
      typeof relatedTarget === "undefined" ||
      relatedTarget === null ||
      (relatedTarget as Element).className.indexOf(
        this.getClassName({ descendantName: "item" })
      ) < 0
    ) {
      if (this.props.onDropdownBlur) {
        this.props.onDropdownBlur();
      }

      this.collapse();
    }
  };

  private calculateListHeight = (
    itemHeight: number,
    visibleItems: number
  ): string => {
    return `${(Math.abs(visibleItems) * itemHeight) / BASE_FONT_SIZE}rem`;
  };

  private setListMaxHeight = (itemHeight: number): void => {
    const { maxVisibleItems } = this.props;

    if (maxVisibleItems) {
      this.setState({
        maxListHeight: this.calculateListHeight(itemHeight, maxVisibleItems),
      });
    }
  };

  private getItemForCurrentValue = (value): IDropdownItem | void => {
    if (value === null || value === undefined) {
      return;
    }

    return this.props.items.find((item) => item.value === value);
  };

  selectItem = (item: IDropdownItem) => {
    if (!this.state.isExpanded) {
      return;
    }

    if (this.props.onItemClicked) {
      this.props.onItemClicked(item);
    } else {
      this.setState({ value: item.value });
    }

    if (this.props.onValueChanged) {
      this.props.onValueChanged(item.value);
    }

    this.collapse();
  };

  navigateToNextItem = (direction: DirectionTypes) => {
    const { items = [] } = this.props;
    const { index, isExpanded } = this.state;

    if (!isExpanded) {
      this.expand();

      return;
    }

    const isLastOrFirstSelected =
      (direction === DirectionTypes.DOWN && index >= items.length - 1) ||
      (direction === DirectionTypes.UP && index <= 0);

    if (isLastOrFirstSelected) {
      return;
    }

    const nextIndex = direction === DirectionTypes.DOWN ? index + 1 : index - 1;

    this.setState({ index: nextIndex });
  };

  handleHomePress = () => {
    this.setState({ index: 0 });
  };

  handleEndPress = () => {
    const { items = [] } = this.props;

    this.setState({ index: items.length - 1 });
  };

  handleTabPress = () => {
    if (!this.state.isExpanded) {
      return;
    }

    this.collapse();
  };

  handleEnterPress = () => {
    const { items = [] } = this.props;
    const { index, isExpanded } = this.state;

    if (!isExpanded || index < 0) {
      this.toggleExpand();

      return;
    }

    if (!this.selectedLinkRef.current) {
      this.selectItem(items[index]);
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isFilterInFocus = this.filterContainerRef.current?.contains(
      event.target as HTMLElement
    );

    switch (event.key) {
      case KeyboardKeys.DOWN_ARROW:
        this.navigateToNextItem(DirectionTypes.DOWN);
        break;
      case KeyboardKeys.END:
        this.handleEndPress();
        break;
      case KeyboardKeys.ENTER:
        this.handleEnterPress();
        break;
      case KeyboardKeys.ESCAPE:
      case KeyboardKeys.TAB:
        this.handleTabPress();
        break;
      case KeyboardKeys.HOME:
        this.handleHomePress();
        break;
      case KeyboardKeys.UP_ARROW:
        this.navigateToNextItem(DirectionTypes.UP);
        break;
      case KeyboardKeys.SPACE:
        if (isFilterInFocus) {
          break;
        }

        this.toggleExpand();
    }

    // Prevent default behavior for all keys except for Tab or Enter if the filter is not in focus
    // as an example: this will stop the document from scrolling as
    // arrow-up/down is pressed.
    // Special case is "Enter" - for that key we want default behaviour
    // only when the items are links (A tag element) so the links will work
    // on "Enter" key press.
    if (
      (event.key !== KeyboardKeys.TAB &&
        event.key !== KeyboardKeys.ENTER &&
        !isFilterInFocus) ||
      (event.key === KeyboardKeys.ENTER && !this.selectedLinkRef.current)
    ) {
      event.preventDefault();
    }
  };

  public render(): JSX.Element {
    const {
      items,
      placeholder,
      labeledBy,
      renderHeader,
      maxVisibleItems,
      renderItem,
      renderFilter,
    } = this.props;

    const { isExpanded, maxListHeight, value, index } = this.state;

    const currentItem = this.getItemForCurrentValue(value);
    let label = placeholder;

    if (currentItem) {
      label = currentItem.label;
    }

    const getListStyles = () => {
      if (!maxVisibleItems) {
        return;
      }

      return { maxHeight: maxListHeight };
    };

    return (
      <div
        className={this.getClassName({
          className: this.props.className,
          modifiers: classNames({
            isExpanded,
          }),
        })}
        onBlur={this.handleBlur}
        onKeyDown={this.onKeyDown}
        role={RoleTypes.PRESENTATION}
        tabIndex={this.props.tabIndex}
      >
        {renderHeader ? (
          renderHeader(
            label,
            isExpanded,
            this.toggleExpand,
            this.expandButtonId
          )
        ) : (
          <DropdownHeader
            buttonRef={this.toggleButtonRef}
            expandButtonId={this.expandButtonId}
            isExpanded={isExpanded}
            labeledBy={`${labeledBy}  ${this.expandButtonId}`}
            text={label}
            toggleExpand={this.toggleExpand}
          />
        )}
        {this.state.isExpanded && (
          <div
            className={this.getClassName({
              descendantName: "listWrapper",
            })}
          >
            {renderFilter &&
              renderFilter(
                this.collapse,
                this.filterContainerRef,
                this.filterInputId
              )}
            <ul
              className={this.getClassName({
                descendantName: "items",
                modifiers: classNames({
                  scroll: maxVisibleItems,
                }),
              })}
              ref={this.listRef}
              role={RoleTypes.LIST_BOX}
              style={getListStyles()}
              tabIndex={0}
            >
              {items.map(
                (item: IDropdownItem, itemIndex: number): JSX.Element => {
                  const isSelected = value === item.value;

                  // measure height only for first item to prevent perfomance issues & only if scroll needed
                  const shouldMeasureHeight =
                    itemIndex === 0 && maxVisibleItems;

                  const itemProps: IDropdownItemProps = {
                    getItemHeight: shouldMeasureHeight
                      ? this.setListMaxHeight
                      : undefined,
                    isFocused: index === itemIndex,
                    isSelected,
                    item,
                    onBlur: this.handleBlur,
                    onItemClicked: this.selectItem,
                  };

                  return renderItem ? (
                    renderItem(itemProps, itemIndex)
                  ) : (
                    <DropdownItem {...itemProps} key={item.label} />
                  );
                }
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
