// libraries
import { forwardRef } from "react";
import { useTranslate } from "@dcl/tools";

// components
import { CrossIcon as CloseIcon } from "../../../common/icons";
import { SearchIcon } from "../../../common/icons";

// utilities
import { getClassNameFactory } from "../../../utilities";

// constants
import {
  AccessibilityClassnames,
  InputTypes,
  RoleTypes,
} from "../../../common/constants";
import { COUNTRY_FILTER_DISPLAY_NAME } from "./constants";

// types
import { CountryFilterProps } from "./types";

const getClassName = getClassNameFactory(COUNTRY_FILTER_DISPLAY_NAME);

export const CountryFilter = forwardRef<HTMLDivElement, CountryFilterProps>(
  (
    { inputId, setValue, shouldShowNothingFoundWarning, onClose, value },
    ref
  ) => {
    const translate = useTranslate();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
      target,
    }) => {
      setValue(target.value);
    };

    return (
      <div className={getClassName("wrapper")} ref={ref} tabIndex={-1}>
        <div className={getClassName("closeButtonWrapper")}>
          <button
            aria-label={translate("AccessibilityHint.Close")}
            className={getClassName("closeButton")}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={getClassName("inputWrapper")}>
          <label className={getClassName("label")} htmlFor={inputId}>
            <span
              className={getClassName("inputIcon")}
              role={RoleTypes.PRESENTATION}
            >
              <SearchIcon />
            </span>
            <span className={AccessibilityClassnames.VISUALLY_HIDDEN}>
              {translate("CountryFilter.Placeholder")}
            </span>
          </label>
          <input
            className={getClassName("input")}
            id={inputId}
            onChange={handleChange}
            placeholder={translate("CountryFilter.Placeholder")}
            type={InputTypes.TEXT}
            value={value}
          />
        </div>
        {shouldShowNothingFoundWarning && (
          <div className={getClassName("nothingFoundWarning")}>
            {translate("CountryFilter.NothingFoundWarning")}
          </div>
        )}
      </div>
    );
  }
);

CountryFilter.displayName = COUNTRY_FILTER_DISPLAY_NAME;
